.landing-about-hero {
    background: linear-gradient(0deg, rgba(23,23,23, 0.7), rgba(23,23,23, 0.7)), url('../img/landing-hero-2.webp') center top/cover no-repeat local;
    box-shadow:  inset 20px 0 20px -20px rgba(59,130,246,1), inset -20px 0px 20px -20px rgba(59,130,246,1);
}

.landing-how-does-it-work-hero {
    background: linear-gradient(0deg, rgba(23,23,23, 0.8), rgba(23,23,23, 0.8)), url('../img/landing-hero-0.webp') center top/cover no-repeat local;
    box-shadow:  inset 20px 0 20px -20px rgba(59,130,246,1), inset -20px 0px 20px -20px rgba(59,130,246,1);
}

.landing-why-use-timanity-hero {
    background: linear-gradient(0deg, rgba(70,70,70, 0.8), rgba(23,23,23, 0.8)), url('../img/landing-hero-3.webp') center top/cover no-repeat local;
    box-shadow:  inset 20px 0 20px -20px rgba(59,130,246,1), inset -20px 0px 20px -20px rgba(59,130,246,1);
}

.landing-footer {
    /* background: linear-gradient(0deg, rgba(23,23,23, 0.8), rgba(70,70,70, 0.8)), url('../img/landing-hero-1.jpeg') center top/cover no-repeat local; */
    box-shadow:  inset 20px 0 20px -20px rgba(59,130,246,1), inset -20px 0px 20px -20px rgba(59,130,246,1);
}

.shadow-inset-how-does-it-work-steps {
    -webkit-box-shadow: 10px 0px 5px -6px #5198B8 inset, -10px 0px 5px -6px #5198B8 inset;
	   -moz-box-shadow: 10px 0px 5px -6px #5198B8 inset, -10px 0px 5px -6px #5198B8 inset;
	        box-shadow: 10px 0px 5px -6px #5198B8 inset, -10px 0px 5px -6px #5198B8 inset;
}