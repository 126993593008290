@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap);

@font-face {
    font-family: 'icomoon';
    src:  url('./fonts/icons/icomoon.eot');
    src:  url('./fonts/icons/icomoon.eot') format('embedded-opentype'),
      url('./fonts/icons/icomoon.ttf') format('truetype'),
      url('./fonts/icons/icomoon.woff') format('woff'),
      url('./fonts/icons/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
  
[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
  .icon-facebook1:before {
    content: "\ea90";
  }
  .icon-instagram:before {
    content: "\ea92";
  }
  .icon-twitter:before {
    content: "\ea96";
  }

body {
  /* background-color: #171717; */
  color: white;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mono-text {
    font-family: 'Share Tech Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.square-text {
    font-family: 'Nova Square', cursive;
}

/* .custom-horizontal-scroll-bar {
    scrollbar-width: 2px;
    scrollbar-color: rgba(96,165,250) rgba(23,23,23,0.7);
}

.custom-horizontal-scroll-bar::-webkit-scrollbar {
    height: 5px;
}

.custom-horizontal-scroll-bar::-webkit-scrollbar-track {
    background: rgba(23,23,23,0.7);
}

.custom-horizontal-scroll-bar::-webkit-scrollbar-thumb {
    background: rgba(96,165,250);
    border-radius: 15px;
} */

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.no-shadow {
    -webkit-box-shadow: none !important;
	   -moz-box-shadow: none !important;
	        box-shadow: none !important;
            
}

*:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

.g_color_flood_body {
    background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);;
}

.g_color_flood_game_body {
    width: 100%;
    max-width: 440px;
}

.g_color_flood_tile {
    /* border-radius: 25%; */
    background-size: 100% 100%;
    transition: all 200ms ease-in;
}

.g_color_flood_tile::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.g_color_flood_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.g_color_flood_selected {
    border: 5px solid white;
}

.g_color_flood_game_options_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.g_color_flood_game_option_header {
    font-size: 50%;
}


.g_2048_game_body {
  background-color: #57407c;
  font-size: 21px;
}
.g_2048_text {
  order: 2;
  padding-top: 40px;
  width: 440px;
  font-weight: bold;
}
.g_2048_board {
  /* order: 1; */
  width: 100%;
  max-width: 450px;
  background-color: rgba(23, 23, 23, 0.3);
  /* border-radius: 7px; */
  outline: none;
  position: relative;
  overscroll-behavior-y: contain !important;
}
.g_2048_board .g_2048_cell,
.g_2048_tile {
  user-select: none;
  cursor: default;
}
.g_2048_details-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.g_2048_resetButton {
  background-color: #3d2963;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-size: 1.2rem;
  padding: 10px 20px;
  border-radius: 7px;
  transition: color 0.3s ease, background-color 0.3s ease;
}
.g_2048_resetButton:hover {
  background-color: #d3386a;
  color: white;
}
.g_2048_score-header {
  font-size: 50%;
}
.g_2048_score-box {
  background-color: #3d2963;
  color: rgba(255, 255, 255, 0.5);
  min-width: 100px;
  padding: 5px 10%;
  border-radius: 7px;
  text-align: left;
  transition: all 1s linear;
  font-size: 1.1rem;
}
.g_2048_cell-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 5px;
}

.g_2048_cell,
.g_2048_tile {
  width: calc(25% - 9px);
  border-radius: 7px;
  color: #766;
  /* background-color: rgba(59, 130, 246, 0.7); */
  background-size: 100% 100%;
}

.g_2048_cell::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.g_2048_tile::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.g_2048_tile {
  margin: 0px;
}
.g_2048_tile0 {
  background-color: #dcb;
}
.g_2048_tile2 {
    background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/2.svg");
}
.g_2048_tile4 {
  background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/4.svg");
}
.g_2048_tile8 {
  background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/8.svg");
}
.g_2048_tile16 {
  background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/16.svg");
}
.g_2048_tile32 {
  background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/32.svg");
}
.g_2048_tile64 {
  background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/64.svg");
}
.g_2048_tile128 {
  background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/128.svg");
}
.g_2048_tile256 {
  background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/256.svg");
  font-size: 45px;
}
.g_2048_tile512 {
    background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/512.svg");
  font-size: 45px;
}
.g_2048_tile1024 {
  background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/1024.svg");
  font-size: 35px;
}
.g_2048_tile2048 {
  position: absolute;
  background-size: contain;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0px;
  background-color: rgba(65, 65, 65, 1);
  background-image: url("./components/Games/Game2048/img/2048.svg");
  font-size: 35px;
}
.g_2048_tile {
  position: absolute;
}
.g_2048_tile.g_2048_merged {
  display: none;
}
.g_2048_tile.g_2048_merged.g_2048_isMoving {
  display: inline;
}
.g_2048_tile.g_2048_new,
.g_2048_overlay {
  animation-duration: 0.2s;
  animation-name: newTile;
  animation-fill-mode: forwards;
  animation-delay: 0.15s;
  transform: scale(0);
}
@keyframes newTile {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.g_2048_overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 0px;
  right: 0px;
  font-size: 55px;
  font-weight: bolder;
  border-radius: 7px;
}
.g_2048_tryAgain {
  background-color: #876;
  color: #fff;
  height: 40px;
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  border: 2px solid white;
}
.g_2048_overlay .g_2048_message {
  color: #666;
}
.g_2048_try-again-style {
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 10;
  transition: all 0.5s ease-in-out;
}
.g_2048_try-again-style:hover {
  background-color: rgba(61, 41, 99, 0.3);
}
.g_2048_game-won {
  background-size: 100% 100%;
}

.g_2048_position_0_0:not(.g_2048_isMoving) {
  top: 1.14%;
  left: 1.14%;
}

.g_2048_position_0_1:not(.g_2048_isMoving) {
  top: 1.14%;
  left: 26.14%;
}

.g_2048_position_0_2:not(.g_2048_isMoving) {
  top: 1.14%;
  left: 51.14%;
}

.g_2048_position_0_3:not(.g_2048_isMoving) {
  top: 1.14%;
  left: 76.14%;
}

.g_2048_position_1_0:not(.g_2048_isMoving) {
  top: 26.14%;
  left: 1.14%;
}

.g_2048_position_1_1:not(.g_2048_isMoving) {
  top: 26.14%;
  left: 26.14%;
}

.g_2048_position_1_2:not(.g_2048_isMoving) {
  top: 26.14%;
  left: 51.14%;
}

.g_2048_position_1_3:not(.g_2048_isMoving) {
  top: 26.14%;
  left: 76.14%;
}

.g_2048_position_2_0:not(.g_2048_isMoving) {
  top: 51.14%;
  left: 1.14%;
}

.g_2048_position_2_1:not(.g_2048_isMoving) {
  top: 51.14%;
  left: 26.14%;
}

.g_2048_position_2_2:not(.g_2048_isMoving) {
  top: 51.14%;
  left: 51.14%;
}

.g_2048_position_2_3:not(.g_2048_isMoving) {
  top: 51.14%;
  left: 76.14%;
}

.g_2048_position_3_0:not(.g_2048_isMoving) {
  top: 76.14%;
  left: 1.14%;
}

.g_2048_position_3_1:not(.g_2048_isMoving) {
  top: 76.14%;
  left: 26.14%;
}

.g_2048_position_3_2:not(.g_2048_isMoving) {
  top: 76.14%;
  left: 51.14%;
}

.g_2048_position_3_3:not(.g_2048_isMoving) {
  top: 76.14%;
  left: 76.14%;
}

.g_2048_row_from_0_to_0 {
  top: 1.14%;
}

.g_2048_row_from_0_to_1 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_0_to_1;
  animation-name: g_2048_row_from_0_to_1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_0_to_1 {
  from {
    top: 1.14%;
  }

  to {
    top: 26.14%;
  }
}

@keyframes g_2048_row_from_0_to_1 {
  from {
    top: 1.14%;
  }

  to {
    top: 26.14%;
  }
}

.g_2048_row_from_0_to_2 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_0_to_2;
  animation-name: g_2048_row_from_0_to_2;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_0_to_2 {
  from {
    top: 1.14%;
  }

  to {
    top: 51.14%;
  }
}

@keyframes g_2048_row_from_0_to_2 {
  from {
    top: 1.14%;
  }

  to {
    top: 51.14%;
  }
}

.g_2048_row_from_0_to_3 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_0_to_3;
  animation-name: g_2048_row_from_0_to_3;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_0_to_3 {
  from {
    top: 1.14%;
  }

  to {
    top: 76.14%;
  }
}

@keyframes g_2048_row_from_0_to_3 {
  from {
    top: 1.14%;
  }

  to {
    top: 76.14%;
  }
}

.g_2048_row_from_1_to_0 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_1_to_0;
  animation-name: g_2048_row_from_1_to_0;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_1_to_0 {
  from {
    top: 26.14%;
  }

  to {
    top: 1.14%;
  }
}

@keyframes g_2048_row_from_1_to_0 {
  from {
    top: 26.14%;
  }

  to {
    top: 1.14%;
  }
}

.g_2048_row_from_1_to_1 {
  top: 26.14%;
}

.g_2048_row_from_1_to_2 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_1_to_2;
  animation-name: g_2048_row_from_1_to_2;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_1_to_2 {
  from {
    top: 26.14%;
  }

  to {
    top: 51.14%;
  }
}

@keyframes g_2048_row_from_1_to_2 {
  from {
    top: 26.14%;
  }

  to {
    top: 51.14%;
  }
}

.g_2048_row_from_1_to_3 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_1_to_3;
  animation-name: g_2048_row_from_1_to_3;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_1_to_3 {
  from {
    top: 26.14%;
  }

  to {
    top: 76.14%;
  }
}

@keyframes g_2048_row_from_1_to_3 {
  from {
    top: 26.14%;
  }

  to {
    top: 76.14%;
  }
}

.g_2048_row_from_2_to_0 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_2_to_0;
  animation-name: g_2048_row_from_2_to_0;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_2_to_0 {
  from {
    top: 51.14%;
  }

  to {
    top: 1.14%;
  }
}

@keyframes g_2048_row_from_2_to_0 {
  from {
    top: 51.14%;
  }

  to {
    top: 1.14%;
  }
}

.g_2048_row_from_2_to_1 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_2_to_1;
  animation-name: g_2048_row_from_2_to_1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_2_to_1 {
  from {
    top: 51.14%;
  }

  to {
    top: 26.14%;
  }
}

@keyframes g_2048_row_from_2_to_1 {
  from {
    top: 51.14%;
  }

  to {
    top: 26.14%;
  }
}

.g_2048_row_from_2_to_2 {
  top: 51.14%;
}

.g_2048_row_from_2_to_3 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_2_to_3;
  animation-name: g_2048_row_from_2_to_3;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_2_to_3 {
  from {
    top: 51.14%;
  }

  to {
    top: 76.14%;
  }
}

@keyframes g_2048_row_from_2_to_3 {
  from {
    top: 51.14%;
  }

  to {
    top: 76.14%;
  }
}

.g_2048_row_from_3_to_0 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_3_to_0;
  animation-name: g_2048_row_from_3_to_0;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_3_to_0 {
  from {
    top: 76.14%;
  }

  to {
    top: 1.14%;
  }
}

@keyframes g_2048_row_from_3_to_0 {
  from {
    top: 76.14%;
  }

  to {
    top: 1.14%;
  }
}

.g_2048_row_from_3_to_1 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_3_to_1;
  animation-name: g_2048_row_from_3_to_1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_3_to_1 {
  from {
    top: 76.14%;
  }

  to {
    top: 26.14%;
  }
}

@keyframes g_2048_row_from_3_to_1 {
  from {
    top: 76.14%;
  }

  to {
    top: 26.14%;
  }
}

.g_2048_row_from_3_to_2 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_row_from_3_to_2;
  animation-name: g_2048_row_from_3_to_2;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_row_from_3_to_2 {
  from {
    top: 76.14%;
  }

  to {
    top: 51.14%;
  }
}

@keyframes g_2048_row_from_3_to_2 {
  from {
    top: 76.14%;
  }

  to {
    top: 51.14%;
  }
}

.g_2048_row_from_3_to_3 {
  top: 76.14%;
}

.g_2048_column_from_0_to_0 {
  left: 1.14%;
}

.g_2048_column_from_0_to_1 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_0_to_1;
  animation-name: g_2048_column_from_0_to_1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_0_to_1 {
  from {
    left: 1.14%;
  }

  to {
    left: 26.14%;
  }
}

@keyframes g_2048_column_from_0_to_1 {
  from {
    left: 1.14%;
  }

  to {
    left: 26.14%;
  }
}

.g_2048_column_from_0_to_2 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_0_to_2;
  animation-name: g_2048_column_from_0_to_2;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_0_to_2 {
  from {
    left: 1.14%;
  }

  to {
    left: 51.14%;
  }
}

@keyframes g_2048_column_from_0_to_2 {
  from {
    left: 1.14%;
  }

  to {
    left: 51.14%;
  }
}

.g_2048_column_from_0_to_3 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_0_to_3;
  animation-name: g_2048_column_from_0_to_3;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_0_to_3 {
  from {
    left: 1.14%;
  }

  to {
    left: 76.14%;
  }
}

@keyframes g_2048_column_from_0_to_3 {
  from {
    left: 1.14%;
  }

  to {
    left: 76.14%;
  }
}

.g_2048_column_from_1_to_0 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_1_to_0;
  animation-name: g_2048_column_from_1_to_0;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_1_to_0 {
  from {
    left: 26.14%;
  }

  to {
    left: 1.14%;
  }
}

@keyframes g_2048_column_from_1_to_0 {
  from {
    left: 26.14%;
  }

  to {
    left: 1.14%;
  }
}

.g_2048_column_from_1_to_1 {
  left: 26.14%;
}

.g_2048_column_from_1_to_2 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_1_to_2;
  animation-name: g_2048_column_from_1_to_2;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_1_to_2 {
  from {
    left: 26.14%;
  }

  to {
    left: 51.14%;
  }
}

@keyframes g_2048_column_from_1_to_2 {
  from {
    left: 26.14%;
  }

  to {
    left: 51.14%;
  }
}

.g_2048_column_from_1_to_3 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_1_to_3;
  animation-name: g_2048_column_from_1_to_3;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_1_to_3 {
  from {
    left: 26.14%;
  }

  to {
    left: 76.14%;
  }
}

@keyframes g_2048_column_from_1_to_3 {
  from {
    left: 26.14%;
  }

  to {
    left: 76.14%;
  }
}

.g_2048_column_from_2_to_0 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_2_to_0;
  animation-name: g_2048_column_from_2_to_0;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_2_to_0 {
  from {
    left: 51.14%;
  }

  to {
    left: 1.14%;
  }
}

@keyframes g_2048_column_from_2_to_0 {
  from {
    left: 51.14%;
  }

  to {
    left: 1.14%;
  }
}

.g_2048_column_from_2_to_1 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_2_to_1;
  animation-name: g_2048_column_from_2_to_1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_2_to_1 {
  from {
    left: 51.14%;
  }

  to {
    left: 26.14%;
  }
}

@keyframes g_2048_column_from_2_to_1 {
  from {
    left: 51.14%;
  }

  to {
    left: 26.14%;
  }
}

.g_2048_column_from_2_to_2 {
  left: 51.14%;
}

.g_2048_column_from_2_to_3 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_2_to_3;
  animation-name: g_2048_column_from_2_to_3;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_2_to_3 {
  from {
    left: 51.14%;
  }

  to {
    left: 76.14%;
  }
}

@keyframes g_2048_column_from_2_to_3 {
  from {
    left: 51.14%;
  }

  to {
    left: 76.14%;
  }
}

.g_2048_column_from_3_to_0 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_3_to_0;
  animation-name: g_2048_column_from_3_to_0;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_3_to_0 {
  from {
    left: 76.14%;
  }

  to {
    left: 1.14%;
  }
}

@keyframes g_2048_column_from_3_to_0 {
  from {
    left: 76.14%;
  }

  to {
    left: 1.14%;
  }
}

.g_2048_column_from_3_to_1 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_3_to_1;
  animation-name: g_2048_column_from_3_to_1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_3_to_1 {
  from {
    left: 76.14%;
  }

  to {
    left: 26.14%;
  }
}

@keyframes g_2048_column_from_3_to_1 {
  from {
    left: 76.14%;
  }

  to {
    left: 26.14%;
  }
}

.g_2048_column_from_3_to_2 {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-name: g_2048_column_from_3_to_2;
  animation-name: g_2048_column_from_3_to_2;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes g_2048_column_from_3_to_2 {
  from {
    left: 76.14%;
  }

  to {
    left: 51.14%;
  }
}

@keyframes g_2048_column_from_3_to_2 {
  from {
    left: 76.14%;
  }

  to {
    left: 51.14%;
  }
}

.g_2048_column_from_3_to_3 {
  left: 76.14%;
}

.g_lights_out_body {
    background-color: #2b2f63;
}

.g_lights_out_game_body {
    width: 100%;
    max-width: 440px;
}

.g_lights_out_tile {
    border-radius: 15%;
    background-size: 100% 100%;
    transition: all 100ms ease-in;
    box-shadow: inset 0rem 0rem 2rem 1rem #971e81, 0.1rem 0.25rem 0.25rem 0.25rem rgba(23,23,23,0.3);
    width: calc(20% - 10px);
}

.g_lights_out_tile::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.g_lights_out_row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.g_lights_out_lit {
    background-color: #df33c0;
    /* box-shadow: 0rem 0rem 0.5rem 0.25rem #af449c, inset 0rem 0rem 0.5rem 0.25rem #af449c; */
    box-shadow: 0rem 0rem 0.5rem 0.25rem #d95ac2, inset 0rem 0rem 1rem 0.5rem rgba(23,23,23,0.3);
}

.g_lights_out_game_options_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.g_lights_out_game_option {
    background-color: #af449c;
    color: rgba(255, 255, 255, 1);
    min-width: 100px;
    padding: 5px 3.41%;
    border-radius: 7px;
    text-align: left;
    transition: all 1s linear;
    font-size: 1.1rem;
}

.g_lights_out_game_option_header {
    font-size: 50%;
}

.g_snake_game_body {
    background: rgb(0,95,50);
    background: linear-gradient(45deg, rgba(10,195,150,1) 0%, rgba(150,150,150,1) 50%, rgba(95,0,50,1) 100%);
}

.g_snake_game_board {
    width: 100%;
    max-width: 440px;
    /* background-color: #17171766; */
    border-radius: 2rem;
    /* padding: 1.5rem; */
    position: relative;
}

.g_snake_game_board_inner {
    /* background-color: #17171766; */
}

.g_snake_game_board_inner::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.g_snake_game_plane {
    position: absolute;
    /* margin: 1.5rem; */
    top: 0;
    left: 0;
    /* z-index: 2; */
    width: calc(100%);
    height: calc(100%);
}

.g_snake_game_snake_blob {
    width: 5%;
    height: 5%;
    position: absolute;
    border: 2px solid white;
    border-radius: 30%;
}

.g_snake_game_snake_blob_bordered {
    border: 5% solid #5d8321;
}

.g_snake_game_food_blob {
    width: 2%;
    height: 2%;
    position: absolute;
    top: 0.5%;
    left: 5.5%;
    background-color: rgb(133, 231, 177);
    border-radius: 1rem;
    animation-name: foodBlob;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    /* z-index: 10; */
}

@keyframes foodBlob {
    0% { background-color: rgb(133, 231, 177); }
    50% { background-color: rgb(50, 50, 50); }
    100% { background-color: rgb(133, 231, 177); }
}

.g_snake_game_snake_blob_eye_2 {
    width: 25%;
    height: 25%;
    position: absolute;
    top: 15%;
    left: 60%;
    background-color: #0099ff;
    border-radius: 5rem;
}

.g_snake_game_snake_blob_mouth_2 {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    right: -1%;
    background-color: #fff;
    border-radius: 5rem;
    animation-name: snake_blob_mouth_move_2;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
}

@keyframes snake_blob_mouth_move_2 {
    0% {
        height: 50%;
        width: 50%;
        top: 50%;
    }
    50% {
        height: 0%;
        width: 0%;
        top: 80%;
    }
    100% {
        height: 50%;
        width: 50%;
        top: 50%;
    }
}

.g_snake_game_snake_blob_eye_0 {
    width: 25%;
    height: 25%;
    position: absolute;
    top: 15%;
    right: 60%;
    background-color: #77bfef;
    border-radius: 5rem;
}

.g_snake_game_snake_blob_mouth_0 {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: -1%;
    background-color: #fff;
    border-radius: 5rem;
    animation-name: snake_blob_mouth_move_0;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
}

@keyframes snake_blob_mouth_move_0 {
    0% {
        height: 50%;
        width: 50%;
        top: 50%;
    }
    50% {
        height: 0%;
        width: 0%;
        top: 80%;
    }
    100% {
        height: 50%;
        width: 50%;
        top: 50%;
    }
}

.g_snake_game_snake_blob_eye_3 {
    width: 25%;
    height: 25%;
    position: absolute;
    bottom: 15%;
    right: 60%;
    background-color: #0099ff;
    border-radius: 5rem;
}

.g_snake_game_snake_blob_mouth_3 {
    width: 50%;
    height: 50%;
    position: absolute;
    left: 50%;
    bottom: -1%;
    background-color: #fff;
    border-radius: 5rem;
    animation-name: snake_blob_mouth_move_3;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
}

@keyframes snake_blob_mouth_move_3 {
    0% {
        height: 50%;
        width: 50%;
        left: 50%;
    }
    50% {
        height: 0%;
        width: 0%;
        left: 80%;
    }
    100% {
        height: 50%;
        width: 50%;
        left: 50%;
    }
}

.g_snake_game_snake_blob_eye_1 {
    width: 25%;
    height: 25%;
    position: absolute;
    top: 15%;
    left: 60%;
    background-color: #0099ff;
    border-radius: 5rem;
}

.g_snake_game_snake_blob_mouth_1 {
    width: 50%;
    height: 50%;
    position: absolute;
    right: 50%;
    top: -1%;
    background-color: #fff;
    border-radius: 5rem;
    animation-name: snake_blob_mouth_move_1;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
}

@keyframes snake_blob_mouth_move_1 {
    0% {
        height: 50%;
        width: 50%;
        right: 50%;
    }
    50% {
        height: 0%;
        width: 0%;
        right: 80%;
    }
    100% {
        height: 50%;
        width: 50%;
        right: 50%;
    }
}

.g_snake_game_option {
    color: rgba(255, 255, 255, 1);
    min-width: 100px;
    padding: 5px 3.41%;
    border-radius: 7px;
    text-align: left;
    transition: all 1s linear;
    font-size: 1.1rem;
    padding-bottom: 0px;
}

.g_snake_game_option_header {
    font-size: 50%;
}

.g_snake_game_animation_paused {
    animation-play-state: paused;
}

.contact-body {
    background: linear-gradient(0deg, rgba(23,23,23,0.5), rgba(23,23,23,0.5)), url('./assets/contact.jpeg') center top/cover no-repeat local;
    box-shadow:  inset 20px 0 20px -20px rgba(59,130,246,1), inset -20px 0px 20px -20px rgba(59,130,246,1);
}