.project-0-hero {
    background: linear-gradient(0deg, rgba(23,23,23, 0.7), rgba(23,23,23, 0.7)), url('../img/project-0.webp') center top/cover no-repeat local;
}

.project-1-hero {
    background: linear-gradient(0deg, rgba(23,23,23, 0.7), rgba(23,23,23, 0.7)), url('../img/project-1.webp') center bottom/cover no-repeat local;
}

.project-2-hero {
    background: linear-gradient(0deg, rgba(23,23,23, 0.7), rgba(23,23,23, 0.7)), url('../img/project-2.webp') center center/cover no-repeat local;
}